import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  Ref,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { FormControl, FormControlProps } from '../FormControl/FormControl';

export type FormTextAreaProps = ComponentPropsWithoutRef<'textarea'> &
  FormControlProps & {
    textareaRef?: Ref<HTMLTextAreaElement | null>;
    onValueChange?: (value: string) => void;
    value?: string;
    rows?: number;
  };

export const FormTextArea = ({
  label,
  isError,
  tooltip,
  textareaRef,
  hint,
  className,
  required,
  visuallyRequired,
  maxLength,
  value,
  disabled,
  readOnly,
  onValueChange,
  onChange,
  rows = 4,
  ...props
}: FormTextAreaProps) => {
  const internalTextareaRef = useRef<HTMLTextAreaElement>(null);
  const id = useId();
  const [internalValue, setInternalValue] = useState('');

  const isControlled = value !== undefined;
  const currentValue = isControlled ? value : internalValue;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    onChange?.(event);
    onValueChange?.(newValue);

    if (!isControlled) {
      setInternalValue(newValue);
    }
  };

  useImperativeHandle(textareaRef, () => internalTextareaRef.current);

  const showCounter = typeof maxLength !== 'undefined';

  const stateClassName = useMemo(() => {
    if (disabled) {
      return 'msq-input-disabled';
    }

    if (readOnly) {
      return 'msq-input-readonly';
    }

    if (isError) {
      return 'msq-input-error';
    }

    return 'hover:msq-input-hover has-[textarea:focus]:msq-input-focus';
  }, [disabled, readOnly, isError]);

  return (
    <FormControl
      className={className}
      hint={hint}
      label={label}
      htmlFor={id}
      isError={isError}
      visuallyRequired={visuallyRequired || required}
      tooltip={tooltip}
    >
      <div
        data-skeleton-target="base"
        className={classNames(
          'msq-input-base p-0 relative flex flex-col',
          'resize-y overflow-auto [&::-webkit-resizer]:bg-transparent',
          stateClassName,
        )}
      >
        <textarea
          id={id}
          className={classNames('bg-transparent outline-none flex-1 resize-none px-3 py-2', {
            'cursor-not-allowed': disabled,
          })}
          ref={internalTextareaRef}
          onChange={handleChange}
          required={required}
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          value={currentValue}
          rows={rows}
          {...props}
        />

        {showCounter && (
          <div
            onClick={() => internalTextareaRef.current?.focus()}
            className={classNames(
              'user-select-none flex justify-end pr-3 pb-2',
              'text-base-text-quaternary text-body-sm-d',
            )}
          >
            {currentValue.length}/{maxLength}
          </div>
        )}
      </div>
    </FormControl>
  );
};
