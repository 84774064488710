import React, { ReactNode } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { CrossLg } from '../../../icons/mono';
import { DialogButtonsWrapper } from '../../Dialog';
import { DialogButton } from '../buttons/DialogButton';
import { DialogCancelButton } from '../buttons/DialogCancelButton';

type ConfirmDialogProps = Dialog.DialogProps & {
  title?: ReactNode;
  description?: ReactNode;
  confirmLabel?: ReactNode;
  onConfirm?: () => void;
  isDisabled?: boolean;
};

export const ConfirmDialog = ({
  title,
  description,
  onConfirm,
  confirmLabel = 'Confirm',
  isDisabled,
  ...props
}: ConfirmDialogProps) => {
  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="msq-dialog msq-dialog-animate msq-dialog-compact-mobile xl-msq:msq-dialog-compact-desktop">
          <Dialog.Close className="msq-dialog-close-button">
            <CrossLg className="msq-dialog-close-button-icon" />
          </Dialog.Close>
          <Dialog.Content
            {...(!description
              ? {
                  'aria-describedby': undefined,
                }
              : {})}
            className="msq-dialog-content"
          >
            {title && <Dialog.Title className="msq-dialog-title">{title}</Dialog.Title>}

            {description && (
              <Dialog.Description className="msq-dialog-description">
                {description}
              </Dialog.Description>
            )}

            <DialogButtonsWrapper>
              <DialogCancelButton />
              <DialogButton onClick={onConfirm} isDisabled={isDisabled}>
                {confirmLabel}
              </DialogButton>
            </DialogButtonsWrapper>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
