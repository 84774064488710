import { ReactNode } from 'react';

import * as RadioGroup from '@radix-ui/react-radio-group';

type FormRadioGroupProps = {
  children: ReactNode;
  defaultValue?: string;
  ariaLabel?: string;
  onChange: (value: string) => void;
  className?: string;
};

export const FormRadioGroup = ({
  defaultValue,
  children,
  ariaLabel,
  onChange,
  className,
}: FormRadioGroupProps) => {
  return (
    <RadioGroup.Root
      onValueChange={onChange}
      className={className}
      defaultValue={defaultValue}
      aria-label={ariaLabel}
    >
      {children}
    </RadioGroup.Root>
  );
};
