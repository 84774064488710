import classNames from 'classnames';

import * as RadioGroup from '@radix-ui/react-radio-group';
import { Typography } from '@ui/uikit/components/common/Typography';
import { Check } from '@ui/uikit/components/icons/mono';

// @todo implement variants 'radio' and 'block'
type FormRadioVariant = 'button';

export type FormRadioItemType = {
  value: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
  variant?: FormRadioVariant;
};

type FormRadioItemProps = FormRadioItemType;

export const FormRadioItem = ({
  value,
  label,
  disabled = false,
  selected = false,
  variant = 'button',
}: FormRadioItemProps) => {
  const showRadioIcon = variant !== 'button';
  const cursorPointer = !disabled;

  const styleClassName =
    variant === 'button'
      ? classNames('form-block', {
          'cursor-pointer': cursorPointer,
          'form-block-disabled': disabled,
          'form-block-checked': selected,
          'hover:form-block-hover': !disabled && !selected,
        })
      : 'flex gap-2';

  const color = disabled ? 'tertiary' : 'primary';

  return (
    <RadioGroup.Item
      value={value}
      id={value}
      className={classNames(
        styleClassName,
        'justify-center msq-radio disabled:!msq-radio-disabled hover:msq-radio-hover data-[state=checked]:msq-radio-checked',
      )}
    >
      {showRadioIcon && (
        <RadioGroup.Indicator className="radio-mark">
          <Check className="w-full h-full" />
        </RadioGroup.Indicator>
      )}
      <label className={classNames({ 'cursor-pointer': cursorPointer })} htmlFor={value}>
        <Typography variant="caption-s-strong" color={color}>
          {label}
        </Typography>
      </label>
    </RadioGroup.Item>
  );
};
