import React, { ReactNode } from 'react';

import classNames from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { CrossLg } from '@ui/uikit/components/icons/mono';

type Props = Dialog.DialogProps & {
  title?: ReactNode;
  titleIsLeftAligned?: boolean;
  description?: ReactNode;
  children?: ReactNode;
  isOpen?: boolean;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  noBottomPadding?: boolean;
  noSidePadding?: boolean;
  withGradient?: boolean;
  footer?: ReactNode;
  usePortal?: boolean;
};

const sideMargins =
  'xl-msq:-mx-6 xs-msq:-mx-4 xl-msq:px-6 xs-msq:px-4 xl-msq:w-[calc(100%+3rem)] xs-msq:w-[calc(100%+2rem)]';

export const CustomDialog = ({
  title,
  titleIsLeftAligned,
  description,
  children,
  isOpen,
  className,
  footer,
  size = 'md',
  noBottomPadding = false,
  noSidePadding = false,
  withGradient = false,
  usePortal = true,
  ...props
}: Props) => {
  const titleContent = titleIsLeftAligned ? (
    <div
      className={classNames(
        'msq-dialog-header -mt-8 pb-4 max-xl-msq:-mt-6',
        !noSidePadding && sideMargins,
      )}
    >
      <Dialog.Title className="msq-dialog-title-left">{title}</Dialog.Title>
    </div>
  ) : (
    <Dialog.Title className="msq-dialog-title">{title}</Dialog.Title>
  );

  const modalContent = (
    <Dialog.Overlay
      className={classNames(
        'msq-dialog msq-dialog-animate msq-dialog-compact-mobile xl-msq:msq-dialog-compact-desktop',
      )}
    >
      <Dialog.Close className="msq-dialog-close-button" data-qa="dialog_close_button">
        <CrossLg className="msq-dialog-close-button-icon" />
      </Dialog.Close>
      <Dialog.Content
        {...(!description
          ? {
              'aria-describedby': undefined,
            }
          : {})}
        className={classNames(
          'msq-dialog-content',
          `msq-dialog-content-${size}`,
          (noBottomPadding || Boolean(footer)) && 'msq-dialog-content-no-bottom-padding',
          noSidePadding && 'msq-dialog-content-no-side-padding',
          withGradient && 'bg-page-gradient',
          'overflow-hidden',
          className,
        )}
      >
        {title ? (
          titleContent
        ) : (
          <VisuallyHidden.Root>
            <Dialog.Title className="msq-dialog-title">Modal title</Dialog.Title>
          </VisuallyHidden.Root>
        )}

        <div className="w-full overflow-auto">
          {description && (
            <Dialog.Description className="msq-dialog-description">
              {description}
            </Dialog.Description>
          )}
          <div className="overflow-auto">{children}</div>
        </div>
        {Boolean(footer) && (
          <div className="p-4 border border-t border-base-border-light flex justify-center">
            {footer}
          </div>
        )}
      </Dialog.Content>
    </Dialog.Overlay>
  );

  return (
    <Dialog.Root open={isOpen} {...props}>
      {usePortal ? <Dialog.Portal>{modalContent}</Dialog.Portal> : modalContent}
    </Dialog.Root>
  );
};
