import { ReactNode } from 'react';

import classNames from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';

type Props = {
  children?: ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
};

export const DialogCancelButton = ({
  children = 'Cancel',
  className = '',
  variant = 'secondary',
}: Props) => {
  return (
    <Dialog.Close className={classNames(`msq-btn msq-btn-lg msq-btn-${variant} w-full`, className)}>
      {children}
    </Dialog.Close>
  );
};
