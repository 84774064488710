import Link from 'next/link';

import React, { ReactNode } from 'react';

import classNames from 'classnames';

type BaseProps = {
  isDisabled?: boolean;
  children: ReactNode;
  variant?: 'primary' | 'secondary';
};

type ButtonProps = BaseProps & {
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  href?: never;
  target?: never;
};

type LinkProps = BaseProps & {
  href: string;
  target: '_blank' | '_self';
  type?: never;
  onClick?: never;
};

type Props = ButtonProps | LinkProps;

const commonClassNames = 'msq-btn msq-btn-lg w-full whitespace-nowrap';

export const DialogButton = ({
  type,
  onClick,
  isDisabled,
  children,
  href,
  variant = 'primary',
  target = '_blank',
}: Props) => {
  if (href) {
    return (
      <Link
        href={href}
        target={target}
        className={classNames(commonClassNames, `msq-btn-${variant}`)}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(commonClassNames, `msq-btn-${variant}`)}
    >
      {children}
    </button>
  );
};
