import { ReactNode } from 'react';

import classNames from 'classnames';

type Props = {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
};

export const DialogButtonsWrapper = ({ className, children, fullWidth = false }: Props) => {
  return (
    <div
      className={classNames(
        'w-full',
        fullWidth ? 'msq-dialog-buttons-full-width' : 'msq-dialog-buttons',
        className,
      )}
    >
      {children}
    </div>
  );
};
