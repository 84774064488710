import React, { ComponentPropsWithoutRef } from 'react';

import { cn } from '@ui/uikit/utils';
import useCopyToClipboard from '@utilities/hooks/useCopyToClipboard';

import { Copy, MarkCheck } from '../../icons/mono';

interface CopyButtonProps extends ComponentPropsWithoutRef<'button'> {
  value?: string;
  iconClassName?: string;
}

export const CopyButton = ({ value = '', iconClassName, ...props }: CopyButtonProps) => {
  const { copyToClipboard, isCopied } = useCopyToClipboard({ copyInterval: 500 });

  const Icon = isCopied ? MarkCheck : Copy;

  return (
    <button type="button" onClick={() => copyToClipboard(value)} {...props}>
      <Icon className={cn('size-4', iconClassName)} />
    </button>
  );
};
